import axios from "axios";
import {Logger} from "./ConsoleUtils";
import { message } from 'antd'
import history from "./history";

let protocol = window.location.protocol
let host = window.location.host
let windowUrl = host.indexOf('localhost') != -1 ?(window.API_U +window.API_P):(protocol+ '//'+ host)
console.log(windowUrl + '------')

let axiosInstance = axios.create({
    baseURL : windowUrl + '/cc-admin',
    timeout: 300000,
    withCredentials: true,
    headers : {
        // 'Authorization' : auth,
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

let axiosLoginInstance = axios.create({
    baseURL : windowUrl + '/cc-admin',
    timeout: 300000,
    withCredentials: true,
    headers : {
        // 'Authorization' : auth,
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

window.axios = axiosInstance
const fetchData = async (type, url, data = undefined) => {
    // let commonHeader = !!randomNum ? {
    //     'Content-Type': 'application/json;charset=UTF-8',
    //     'nonce': randomNum,
    //     'sign': encrypt(randomNum + timestemp)
    // } : undefined
    try{
        if(url === '/login' || url === '/captcha'){
            return await axiosLoginInstance({
                method: type,
                url: url,
                data : data,
            });
        }else{
            return await axiosInstance({
                method: type,
                url: url,
                data : data,
                // headers : commonHeader
            });
        }

        
    }catch(e){
        console.log('err', e);
        if(!!e.response && e.response.status > 400){
            switch(e.response.status){
                case 401 : {
                    if (localStorage.getItem('sid')) {
                        message.error('登录已失效，请重新登录')
                        Logger.trace('token已失效', e.response)
                        window.localStorage.removeItem('sid')
                        // alert('token已失效');
                        history.push('/login')
                    }
                    break;
                }
                case 9000 : {
                    Logger.trace('接口调用失败', e.response);
                    break;
                }
                default : {
                    Logger.trace('接口调用失败', e.response);
                }
            }
        }else{
            Logger.error("网络错误", e);
        }
        return e.response;
    }
};

export const downloadFile = async (url, header = {}) => {

    const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
    }
    const response = await axiosInstance({
        method: 'GET',
        url,
        headers: {
            ...headers,
            ...header
        },
        responseType: 'blob',
    });
    if (response.status === 200) {
        const filename = '会议.xls';
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = decodeURIComponent(filename);
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        return response.data;
    }
}

// let timestemp;
// let randomNum;
// const getSysTime = async () => {
//     timestemp = await axiosInstance({
//         method: 'get',
//         url: '/api/v2/auth/getSysTime',
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//         }
//     }).then((response) => {
//         return(response.data.data);
//     }).catch((e) => {
//         console.log('axios or network error');
//         // message.error('服务器时间获取失败');
//         return undefined;
//     })
//     randomNum = createUuid();
// }
// getSysTime();
// setInterval(()=> {
//     getSysTime();
// }, 60000);


export const SetToken = (token) => {
    console.warn(token)
    axiosInstance.defaults.headers.common['Authorization'] = token;
}

export const ApiUtil = {
    get : async (url) => {
        return await fetchData('get', url);
    },
    post : async (url, data) => {
        return await fetchData('post', url, data);
    },
    put : async (url, data) => {
        return await fetchData('put', url, data);
    },
    patch : async (url, data) => {
        return await fetchData('patch', url, data);
    },
    delete : async (url, data) => {
        return await fetchData('delete', url, data);
    },
    download: async (url, data) => {
        return await downloadFile(url, {});
    },
}

let axiosControlstance = axios.create({
    baseURL : windowUrl + '/cc-conference',
    timeout: 300000,
    withCredentials: true,
    headers : {
        // 'Authorization' : auth,
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

export const ApiControlUtil = {
    put : async (url, data) => {
        return await fetchData2('put', url, data);
    },
    post : async (url, data) => {
        return await fetchData2('post', url, data);
    },
    delete : async (url, data) => {
        return await fetchData2('delete', url, data);
    }
}

const fetchData2 = async (type, url, data = undefined) => {
    // let commonHeader = !!randomNum ? {
    //     'Content-Type': 'application/json;charset=UTF-8',
    //     'nonce': randomNum,
    //     'sign': encrypt(randomNum + timestemp)
    // } : undefined
    try{
        return await axiosControlstance({
            method: type,
            url: url,
            data : data,
            // headers : commonHeader
        });
    }catch(e){
        console.log('err', e);
        if(!!e.response && e.response.status > 400){
            switch(e.response.status){
                case 401 : {
                    if (localStorage.getItem('sid')) {
                        message.error('登录已失效，请重新登录')
                        Logger.trace('token已失效', e.response)
                        window.localStorage.removeItem('sid')
                        history.push('/login')
                    }
                    break;
                }
                case 9000 : {
                    Logger.trace('接口调用失败', e.response);
                    break;
                }
                default : {
                    Logger.trace('接口调用失败', e.response);
                }
            }
        } else {
            Logger.error("网络错误", e);
        }
        return e.response;
    }
};





let emcuAxiosInstance = null;

export const setEmcuAxiosInstance = (url, auth) => {
    emcuAxiosInstance = axios.create({
        baseURL : url,
        timeout: 100000,
        headers : {
            'Authorization' : auth,
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
}


export const EmcuApiUtil = {
    get : (url) => {
        if(!!emcuAxiosInstance){
            return emcuAxiosInstance({
                method: 'get',
                url: url,
            });
        }
        
    },
    post : (url, data) => {
        if(!!emcuAxiosInstance){
            return emcuAxiosInstance({
                method: 'post',
                url: url,
                data: data,
            });
        }
    },
    put : (url, data) => {
        return emcuAxiosInstance({
            method: 'put',
            url: url,
            data: data,
        });
    },
    patch : (url, data) => {
        return emcuAxiosInstance({
            method: 'patch',
            url: url,
            data: data,
        });
    },
    delete : (url) => {
        return emcuAxiosInstance({
            method: 'delete',
            url: url,
        });
    }
}






