const defaultState = {
    show:true
};

export const tabbarReducer = (state = defaultState, action) => {
    switch (action.type) {

        case 'setTabbarReducer' : {
            state = {
                ...state,
                ...action.payload
            };
            break;
        }

        case 'resetTabbarReducer' : {
            state = {
                ...defaultState
            };
            break;
        }

        

        default : {

        }

    }
    return state;
};