import React from "react";
const AddressBook = React.lazy(() => import(/* webpackChunkName: "personal" */ "./addressBook"));
const Booking = React.lazy(() => import(/* webpackChunkName: "personal" */ "./booking"));
const Work = React.lazy(() => import(/* webpackChunkName: "personal" */ "./work"));
const Panel = React.lazy(() => import(/* webpackChunkName: "personal" */ "./panel"));
const PersonalCenter = React.lazy(() => import(/* webpackChunkName: "personalCenter" */ "./personalCenter"));
const Detail = React.lazy(() => import(/* webpackChunkName: "personal" */ "./detail"));
const Room = React.lazy(() => import(/* webpackChunkName: "personal" */ "./room"));
export {
    AddressBook,Booking,Work,Detail,Room, Panel, PersonalCenter
}