import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './style/app.less';
import './style/three-dots.less';
import "./style/icon-signal/style.css";
import "./style/common.less";

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')

ReactDOM.render(<App/>, document.getElementById('root'));