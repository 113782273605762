export const portalReducer = (state = {
    token : undefined,
    myData : undefined,
    menuOn : ''
}, action) => {
    switch (action.type) {
        case 'setPortalReducer' : {
            state = {
                ...state,
                ...action.payload
            };
            break;
        }
        
        default : {

        }
    }
    return state;
};
