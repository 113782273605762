import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { Layout } from "antd";
import { Route, Redirect, Switch, } from "react-router-dom";

import Menu from "./Menu";
import { AddressBook, Booking, Work, Detail ,Room, Panel, PersonalCenter } from './phonepages';
import { Loading, Button } from '../../common/extendedAntdWidget/export';
import Framework from "./Framework";

const { Header, Content, Footer } = Layout;
class Phone extends React.Component {


    render() {
        return (
            <Layout
                style={{ height: '100%', overflow: 'hidden', display: 'flex' }}
                id={'my-root'}
            >
                {/* <Header />
                 */}

                <Content style={{ backgroundColor: '#F1F1F2', flex: 1, overflowY: 'hidden', overflowX: 'hidden', display: 'flex' }}>
                    <div
                        id={"portal-content"}
                        style={{
                            flex: 1,
                            // boxShadow : '0 0 6px 0 rgb(0 0 0 / 14%)', 
                            background: 'transparent', width: '100%', display: 'flex'
                        }}
                    >
                        <Switch>
                            <Redirect
                                from={"/"}
                                to={"/home"}
                                exact
                            />
                            <Route
                                path={"/work"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><Work me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />
                            <Route
                                path={"/home"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><Panel me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />

                            <Route
                                path={"/booking"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><Booking me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />

                            <Route
                                path={"/addressbook"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><AddressBook me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />
                            <Route
                                path={"/personalCenter"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><PersonalCenter me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />

                            <Route
                                path={"/detail"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><Detail me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />

<Route
                                path={"/room"}
                                component={() => {
                                    return <Suspense fallback={<Loading />}><div style={{ flex: 1, overflowY: 'overlay' }}><Room me={this.props.portal.myData} /></div></Suspense>
                                }}
                            />


                        </Switch>
                    </div>

                </Content>
                <Menu />

            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        portal: state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },


    }
};

export default connect(mapStateToProps, mapDispachToProps)(Phone);