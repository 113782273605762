/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Tag} from 'antd';

const style = {
    borderRadius: 9, 
    border : 'none',
    padding : '1px 10px',
}

export class ZhTag extends React.Component {
    render () {

     
        return <Tag {...this.props} style={!this.props.style ? style : {...style, ...this.props.style, }}/>
    }
}