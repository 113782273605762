/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Row, Col } from 'antd';


/*formItem = {
    name : 'name',
    leftSpan : 4,
    title : ReactComponent,
    rightSpan : 16,
    widget : ReactComponent,
    rules : []
}*/

export class FormItem extends React.Component {
    render() {
        return <Row style={this.props.style} gutter={8}>
            <Col span={this.props.formItem.leftSpan} style={{color : '#a0a0a0', lineHeight : '32px', paddingRight : 15, textAlign : 'right', fontSize : 16}}>
                {this.props.formItem.title}
            </Col>

            <Col span={this.props.formItem.rightSpan}>
                <Form.Item
                    valuePropName={this.props.formItem.valuePropName}
                    name={this.props.formItem.name}
                    rules={this.props.formItem.rules}
                >
                    {this.props.formItem.widget}
                </Form.Item>
            </Col>
        </Row>
    }
}