const connKey = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBANfq6DRRtoAMd7VCc9Jnv3KcEIMctz8V1glnSeYFOcA4Co2tC+bQ3L5VnRwp8hfDzXa8GgoR7Qxnd382qlacOMB6TkAzseYTbV5amcSuchBkF3yHCdWro9LkWgOTc7Byvlg2vQKBk+GhkDA0sLhQms9FysIGfHuM6HZW2VhftcelAgMBAAECgYB7MPvrXfPSe0qRkKikk60XpxOEuw2SYNMN6nCvXKvmkBL1YpY3y1MnBZHuT8Bg7sZ7yDbTKAcn3KDBZF8rq1Z07mJpAFS0S9T5duqILMO8EvaUJ22Mknljy7KX2AnVM73h9TRN4JTdhXSqhhvK3kAJ971DTyHRtHEHsPOq0QtsAQJBAPwqBKdu8QdZeIXhOJpWMNuFFcBWylmrg7Seh3kMTuZPzi7MeoRqeWxqElpOddkTcvBTj3+IQBTohnb5c9e7H3ECQQDbM7yt6B127WmHW1VB7O26ZTisLbQ76UJVzy2ZIfNBl+g3VBV74hutMS416vYoJUOiNIUliTQyM+LvWg+olHl1AkAsogNQ34IqGtwKVmL+bmMXd+bxtGr8lC8ruSOheVw9x8Jm5iqJZY84cU3uThTt51Ng1ml/2U3+ZWnnhR/L/4MBAkEAnwYsttmzLolUSr+G3bn+SYCPmdHadLBn0IrqILMRoB+Ndz5IC8TgoUgRp0obZMqMA4wooC+mr1kXJN/ohjuY1QJAGGAetCaGZJ0K4MuokkYXg+byjPZONDib2qync92ZMh3OQLmg7WaVSbIepwr1+qS9hUdxGzhTzdvgOjQC641Uxg==';
const pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCWpGKwl2YU6QddrfEeNuLRw25niZfbRYG+JJW5Lxc87FtClKBQdKqNFjdDhToTjRpN0Wo+MUdikOv4oVSaHDhxEJwDq04FD1wbfQrxg7iVcv+VjY7lSgkeSzrZoN2DTWnb3AnbiL7L/YhaWebv6rBhj2T/UpC7H+5tV5OkfBwXIwIDAQAB';

const connKey1 = 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDBm8qfgWujNWehEHoUgzpjqXm2suQN1fx6O41TKCaJO46xxIzABrMT0XlatkEFuv2JM3GBe0FNxuJQrR4RxnZS6nmqFLfckxSVVa7mOqIE0yRHGzs2+Lg6Bwz6CFgnpXf3p6dSzHUeXElxLkjlox76KDKamRAP4X+qA1D3O8OvrVmFm+l5NUns10VWfJVldAUtgE/qZuci2r2O9zyXTm5qHGhpLGFIleZo4t0qk4plmhzBzZWrImRzfLYuNFH+JasD7c/prcutzDw+KEh85o+EmroMiiUqREiapkjgNMOTMLB7AtJMPohNfCYwcKYnD/QkORjbE46cwXsS3XEFkYWpAgMBAAECggEALm2RtZZvnX/dDf7Jh8nAYs3tZiaYEclS9mz5gwuJ0jb6DR85MYNpxkLheyNhjGI/EnvHqGlKS8B53SbmoxSYo2GfM24seTS8JuOZQZSl7cpEVNju5kvWwfOW5Q7DNR61GiqWMYDeRR2UbxPOX2MytoKVQAWcmHJXDwokjGVElAfuktWy49wKQskyy1zdDTkcCHpCXrfhM5MEFrq2+WwNWUkLou0eNIYCOxf2/ob3mywBKJ7gtko85I73lI8kEgIA/7F8Njxtt/l9WSuWFAc/1O//XnijMtGb8jOx4xX1iVFH3dyYniWMTYFIT5b5dpQQWrENZjb7f5XNUhL7Y0lyAQKBgQD46MzgNynPiIzQ/hYOPq42zUwhiV73FWdJvXsYMczovgsqpfKMegbY2L9SRjtfKv1cGScAjZOM52IfxMfzmV/QfuoMGQAln5pC0bbCFD+GUcwQsd9vsliEInwgtPpqwDsFKRtux+jdckA6njdVKBOBrZyzXEWNEaPJdz1eiZsMcQKBgQDHH7Qp4EMnArH2Y3uRFh9gPEPnFPTpznDqs1ZCK7pb0eJg8pUY3mNrHB/cGgBeRt46wH9WJ71P2IjlMZrhVu0HYoGmG7pric9e9ZUtiqR/QlE68FTOK+9vtC3/7AdRu9ktMNyppVVumh3rKoGfe/8izBLIzkoZ72+UrjChd0IIuQKBgHtPQWf2jBUPLcu/hIesD7yAei3HEy/O/6ttXlT/HudSds+7BG0Jauj0f9uvhWxBLOD6aPMCME3GtzrLzs7kda4hoas6m3MIHXDq2eJOC6ZsUW8X33xDW0yilOHTJpfKAbMtyTRZfwhtuFoR1ZGvV/KPYqkMljwrt/tvwgxamWBRAoGAKB1/+0u925cUaTHji/gr/ARIHb7AFHZNk0rr3srWcXcYJzXXybnLx6FT3dCzMEsQrD9pp967qeFSuvHCZRFREULMIb3GUbHiwBuzoQgkWarvmHMgcqSxSQzAk6VmWBYDC7KjUd4IFw8fZegbUxj07nq0chX1IcJ19Tseevj+qakCgYEAq1s+6arH5jI0eBjIb9AnhZTW5oBFhkxOiAs7QiggE/cFuVT+9/JmfDhjG/UwVWEHY4tgML8vO/TmS7gT2GFh5vASHFatRgbjLx9HI0+KGQjjwzdCAyQMMVwbwvH36dCOJzJLb20G94AAj99gkTuCPRWrR7JvBmJjL3qKLflBKPo='
const pubKey2 = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyIZzn4Plfih7oisz1ouUMa1rUAPwtA6KxhSYaILv4euZb02SJDQgpnNvXJ3UjT2DAbzOG/9LnJivZo6TpyOSE+zvRS1nYvbtIGgMckW4QPRQt0B/G9NKshUXGrkWNEt/kwHlNL5KcBKzBvWdYyXvGQz422vId7jIZfnzIicXtsVHxTKe1Zi+i92/AiD5umkEqGl0TCrmAsROgae2EMjR/VGoNO8o3Rvmp8hBAYCavTML644FPGEoCGEB4Bq2L8P0UJT+qEZnkeA6V45yfQ33InWLPjxg/6WUx59FxQti7vS5ZGPgR90LDUu8KUaSpsc4a6XuC9vUukWTtZxEt2Z0lQIDAQAB'


const decrypter = new window.JSEncrypt();
const encrypter = new window.JSEncrypt();

export const decrypt = (str) => {
    decrypter.setPrivateKey(connKey1);
    return decrypter.decrypt(str);
}


export const encrypt = (str) => {
    encrypter.setPublicKey(pubKey2);
    return encrypter.encrypt(str);
}