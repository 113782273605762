import React from 'react'

const Personal = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../personal/personal')
)
const Booking = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../reserve/reduxBooking')
)
const Conference = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../conferences/reduxConference')
)
const Meeting = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../meeting/reduxConference')
)
const MeetingList = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../meeting/reduxTabConference')
)
const Control = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../conferences/control')
)
const Dashboard = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../dashboard/dashboard')
)
const Room = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../room/reduxRoom')
)
const Live = React.lazy(() =>
	import(/* webpackChunkName: "personal" */ '../live/live')
)
const Users = React.lazy(() =>
	import(/* webpackChunkName: "users" */ '../users/users')
)
const Panel = React.lazy(() =>
	import(/* webpackChunkName: "panel" */ '../panel/panel')
)
const MeetingMap = React.lazy(() =>
	import(/* webpackChunkName: "panel" */ '../map/map')
)
const Workbench = React.lazy(() =>
	import(/* webpackChunkName: "workbench" */ '../../pages/workbench/index')
)
const SharedBench = React.lazy(() =>
	import(/* webpackChunkName: "workbench" */ '../../pages/shared-bench/index')
)

export {
	Personal,
	Booking,
	Conference,
	Dashboard,
	Room,
	Live,
	Control,
	Users,
	Panel,
	Meeting,
	MeetingMap,
	Workbench,
	SharedBench,
	MeetingList
}
