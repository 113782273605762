import {createStore, combineReducers, applyMiddleware} from "redux";
import {createLogger} from 'redux-logger';
import {portalReducer} from '../reducer/portalReducer';
import {bookingReducer} from '../reducer/bookingReducer';
import { conferencesReducer} from '../reducer/conferencesReducer';
import { roomReducer} from '../reducer/roomReducer';
import { tabbarReducer} from '../reducer/tabbarReducer';

// let watcher = window.localStorage.getItem('reduxWatcher');
// let loggerMode = window.localStorage.getItem('loggerMode') === 'true' ? true : false;
// const rdLogger = createLogger({
//     predicate: (getState, action) => action.type === watcher || loggerMode
// });
const rdLogger = createLogger();


// window.setReduxWatcher = (reducer) => {
//     watcher = reducer;
// }

export const portalStore = createStore(combineReducers(
    {
        portal : portalReducer,
        tabbar : tabbarReducer,
        // booking : bookingReducer,
        // conference : conferencesReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);


export const bookingStore = createStore(combineReducers(
    {
        booking : bookingReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);

export const confStore = createStore(combineReducers(
    {
        conference : conferencesReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);

export const roomStore = createStore(combineReducers(
    {
        room : roomReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);

export const tabbarStore = createStore(combineReducers(
    {
        menu : tabbarReducer,
    }),
    {},
    applyMiddleware(rdLogger)
);