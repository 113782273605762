import { Badge, TabBar } from 'antd-mobile';
import {
    AppOutline,
    MessageFill,
    MessageOutline,
    PhonebookOutline,
    UnorderedListOutline,
    TeamOutline,
    UserOutline,
} from 'antd-mobile-icons';
import { EventEmitter } from "events";
import React from 'react';
import { connect } from "react-redux";
import { withRouter, } from "react-router-dom";

export const menuEmitter = new EventEmitter();


class phonePortalMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            defaultSelectedKeys: ['0']
        }

        this.tabs = [
            {
              key: 'home',
              title: '会议室看板',
              icon: <UnorderedListOutline />,
            },
            {
              key: 'work',
              title: '日程',
              icon: <AppOutline />,
              badge: Badge.dot,
            },
            {
              key: 'addressbook',
              title: '通讯录',
              icon: <PhonebookOutline />,
              badge: '5',
            },
            // {
            //   key: 'message',
            //   title: '我的消息',
            //   icon: (active) =>
            //     active ? <MessageFill /> : <MessageOutline />,
            //   badge: '99+',
            // },
            {
              key: 'personalCenter',
              title: '个人中心',
              icon: <UserOutline />,
            },
          ]

    }

    componentDidMount() {
        menuEmitter.addListener('setSeleted', (data) => {
            // console.warn(data);
            this.setState({
                defaultSelectedKeys: []
            })
        })

    }

    render() {

        return (
            <TabBar style={{ background:'white','borderTop': 'solid 1px var(--adm-color-border)', display : this.props.tabbar.show ? undefined : 'none'}} onChange={(key) => {
                // console.log(key)
                this.props.history.push( `/${key}`)
            }}>
                {this.tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabbar : state.tabbar,
    }
};

export default connect(mapStateToProps, undefined)(withRouter(phonePortalMenu));

