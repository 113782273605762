import dayjs from "dayjs";

const defaultstate = {
    watchType : 'day',
    watchDay : dayjs(),
    watchWeek : dayjs().day(1),
    watchMonth : dayjs().date(1),
    loading : true,
    selectedRoom : undefined
}

export const roomReducer = (state = defaultstate, action) => {
    switch (action.type) {
        case 'setRoomReducer' : {
            state = {
                ...state,
                ...action.payload
            };
            if(state.loading){
                state.selectedRoom = undefined
            }

            break;
        }

        case 'resetRoomReducer' : {
            state = defaultstate
            break;
        }
        
        default : {

        }
    }
    return state;
};
