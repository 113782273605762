/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Button} from 'antd';


export class ZhButton extends React.Component {
    render () {

     
        return <Button {...this.props} style={!this.props.style ? {borderRadius: 5} : {borderRadius: 5, ...this.props.style, }}/>
    }
}