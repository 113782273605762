import React from 'react';
import {Provider} from "react-redux";
import {portalStore} from "../redux/store/webStores";
import Portal from './Portal';

class App extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            document.querySelector('iframe') && document.body.removeChild(document.querySelector('iframe'));
        }, 2000)
    }
    render () {
        return (
            <Provider store={portalStore}>
                <Portal/>
            </Provider>
        );
    }
}

export default App;