import { Toast } from 'antd-mobile';
import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loading from "../common/extendedAntdWidget/Loading";
import Content from '../components/content/Content';
import { Live } from "../components/content/pages";
import Login from '../components/login/Login';
import Phone from '../components/phone/Phone';
import { ApiUtil, SetToken } from '../utils/ApiUtils';
import { getUrlParam } from '../utils/CommonUtils';
import { message } from 'antd';

// const SiteContent = React.lazy(() => import(/* webpackChunkName: "siteContent" */ "./siteContent"));
// import SiteContent from './siteContent';

class Portal extends React.Component {
    state = {
        showNoData: false
    }
    hasCode = false

    async componentDidMount() {
        var query = window.location.href.split('?')[1];
        console.log(query);
        let wxCode = '';
        this.hasCode = false

        if (query !== undefined) {
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] === 'code') {
                    wxCode = pair[1];
                    this.hasCode = true
                    break;
                }
            }
        }

        let ticket = getUrlParam('ticket')
        let base64Result = getUrlParam('result')

        if(!!ticket){
            const resp = await ApiUtil.post("/login",  {
                loginType: 2,
                ticket : ticket
            });

            if (resp.data.code === 200) {
                SetToken(resp.data.data.token)
                localStorage.setItem('sid', resp.data.data.token)
            }
        } else if (!!base64Result) {
            let result = JSON.parse(decodeURIComponent(escape(window.atob(base64Result))))
            if (result.code === 200) {
                SetToken(result.data.token)
                localStorage.setItem('sid', result.data.token)
            } else {
                message.error(result.message)
            }
        } else if (!!localStorage.getItem('sid')) {
            SetToken(localStorage.getItem('sid'))
        }
        try {
            // throw(new Error())
            let resp = await ApiUtil.get("/check");
            if (resp.status === 200 && !!resp.data.data.name) {

                let myData = resp.data.data;
                myData.confType = [
                    // {value: "RTC会议", key: "RTC"},
                    { value: "腾讯会议", key: "TENCENT" },
                    { value: "本地会议", key: "TRADITIONAL" },
                    // {value: "混合会议", key: "RTC_TENCENT"},
                ]
                myData.tenantUuid = resp.data.data.tenant.uuid;
                this.props.setPortalReducer({
                    myData: myData,
                    token: localStorage.getItem('sid')
                })

                document.title = myData.tenant.name;
                let favicon = document.getElementById('favicon');
                favicon.href = myData.tenant.iconUrl;

            } else {
                if (this.hasCode) {//企业微信登录
                    let params = {}
                    params.code = wxCode
                    params.loginType = 1
                    const resp = await ApiUtil.post("/login", params);
                    if (resp.data.code === 200) {
                        SetToken(resp.data.data.token)
                        localStorage.setItem('sid', resp.data.data.token)

                        let host = resp.data.data.socketIONode.host;
                        let port = resp.data.data.socketIONode.port;
                        let scheme = (!!resp.data.data.socketIONode && resp.data.data.socketIONode.length > 0) ? resp.data.data.socketIONode[0] : 'http';
                        if (!!host && !!port) {
                            let url = scheme + '://' + host + ':' + port;
                            console.log('获取的socketIO地址', url);
                            localStorage.setItem('socket', url)
                        }

                        let whoamiData = await ApiUtil.get("/check");
                        if (whoamiData.status === 200 && !!whoamiData.data.data.name) {
                            let myData = whoamiData.data.data
                            myData.tenantUuid = myData.tenant.uuid;
                            this.props.setPortalReducer({
                                token: resp.data.data.token,
                                myData: myData
                            });

                            document.title = myData.tenant.name;
                            let favicon = document.getElementById('favicon');
                            favicon.href = myData.tenant.iconUrl;

                        } else {
                            this.setState({
                                showNoData: true
                            })
                            this.props.setPortalReducer({
                                myData: {}
                            })
                            let msg = !!resp.data.message ? resp.data.message : '请求失败'
                            Toast.show({
                                icon: 'fail',
                                content: msg
                            })
                        }

                    } else {
                        this.setState({
                            showNoData: true
                        })
                        this.props.setPortalReducer({
                            myData: {}
                        })
                        let msg = !!resp.data.message ? resp.data.message : '请求失败'
                        Toast.show({
                            icon: 'fail',
                            content: msg
                        })
                    }

                } else {
                    this.props.setPortalReducer({
                        myData: {}
                    })
                }
            }
        } catch (e) {
            this.props.setPortalReducer({
                myData: {}
            })

        }

    }

    render() {
        return (
            this.state.showNoData ? <div></div> : (!this.props.portal.myData) ?
                <Loading />
                :
                <HashRouter>
                    <Switch>
                        <Route
                            path={"/live"}
                            exact
                            children={() => {
                                return <Suspense fallback={<Loading />}><Live /></Suspense>;
                            }}
                        />
                        <Route
                            path={"/login"}
                            exact
                            children={() => {
                                return <Suspense fallback={<Loading />}><Login /></Suspense>;
                            }}
                        />

                        <Route
                            path={"/"}
                            children={(data) => {
                                const match = data.match;
                                if (match) {
                                    if (!this.props.portal.myData.name) {
                                        return <Suspense fallback={<Loading />}><Login /></Suspense>;
                                    }

                                }
                                {
                                    if (
                                        navigator.userAgent.match(/Android/i) ||
                                        navigator.userAgent.match(/webOS/i) ||
                                        navigator.userAgent.match(/iPhone/i) ||
                                        navigator.userAgent.match(/iPad/i) ||
                                        navigator.userAgent.match(/iPod/i) ||
                                        navigator.userAgent.match(/BlackBerry/i) ||
                                        navigator.userAgent.match(/Windows Phone/i)
                                    ) {
                                        return (
                                            <Suspense fallback={<Loading />}><Phone /></Suspense>
                                        );
                                    } else {
                                        return (
                                            <Suspense fallback={<Loading />}><Content /></Suspense>
                                        );
                                    }
                                }

                            }}
                        />
                    </Switch>
                </HashRouter>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        portal: state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },


    }
};

export default connect(mapStateToProps, mapDispachToProps)(Portal);