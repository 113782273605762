/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-cond-assign */
export const getUrlParam = (variable) => {
    try {
        var query = window.location.href.split('?')[1];
        // console.log(query);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
    } catch (e) {
        return undefined;
    }

    return undefined;
}

export function isFirefox() {
    return window.navigator.userAgent.match('Firefox') !== null;
}

export function isChrome() {
    return window.navigator.userAgent.match('Chrome') !== null;
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}

export function isEdge() {
    return window.navigator.userAgent.match(/Edge\/(\d+).(\d+)$/) !== null;
}

export function createUuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// Returns system information.
// Format: {sdk:{version:**, type:**}, runtime:{version:**, name:**}, os:{version:**, name:**}};
// eslint-disable-next-line require-jsdoc
export function sysInfo() {
    const info = Object.create({});
    info.sdk = {
        type: 'JavaScript',
    };
    // Runtime info.
    const userAgent = navigator.userAgent;
    const firefoxRegex = /Firefox\/([0-9\.]+)/;
    const chromeRegex = /Chrome\/([0-9\.]+)/;
    const edgeRegex = /Edge\/([0-9\.]+)/;
    const safariVersionRegex = /Version\/([0-9\.]+) Safari/;
    let result = chromeRegex.exec(userAgent);
    if (result) {
        info.runtime = {
            name: 'Chrome',
            version: result[1],
        };
    } else if (result = firefoxRegex.exec(userAgent)) {
        info.runtime = {
            name: 'Firefox',
            version: result[1],
        };
    } else if (result = edgeRegex.exec(userAgent)) {
        info.runtime = {
            name: 'Edge',
            version: result[1],
        };
    } else if (isSafari()) {
        result = safariVersionRegex.exec(userAgent);
        info.runtime = {
            name: 'Safari',
        };
        info.runtime.version = result ? result[1] : 'Unknown';
    } else {
        info.runtime = {
            name: 'Unknown',
            version: 'Unknown',
        };
    }
    // OS info.
    const windowsRegex = /Windows NT ([0-9\.]+)/;
    const macRegex = /Intel Mac OS X ([0-9_\.]+)/;
    const iPhoneRegex = /iPhone OS ([0-9_\.]+)/;
    const linuxRegex = /X11; Linux/;
    const androidRegex = /Android( ([0-9\.]+))?/;
    const chromiumOsRegex = /CrOS/;
    if (result = windowsRegex.exec(userAgent)) {
        info.os = {
            name: 'Windows NT',
            version: result[1],
        };
    } else if (result = macRegex.exec(userAgent)) {
        info.os = {
            name: 'Mac OS X',
            version: result[1].replace(/_/g, '.'),
        };
    } else if (result = iPhoneRegex.exec(userAgent)) {
        info.os = {
            name: 'iPhone OS',
            version: result[1].replace(/_/g, '.'),
        };
    } else if (result = linuxRegex.exec(userAgent)) {
        info.os = {
            name: 'Linux',
            version: 'Unknown',
        };
    } else if (result = androidRegex.exec(userAgent)) {
        info.os = {
            name: 'Android',
            version: result[1] || 'Unknown',
        };
    } else if (result = chromiumOsRegex.exec(userAgent)) {
        info.os = {
            name: 'Chrome OS',
            version: 'Unknown',
        };
    } else {
        info.os = {
            name: 'Unknown',
            version: 'Unknown',
        };
    }
    info.capabilities = {
        continualIceGathering: false,
        unifiedPlan: true,
        streamRemovable: info.runtime.name !== 'Firefox',
    };
    return info;
}


export const secondsToMinutes = seconds => {
    let time = Math.round(seconds);
    if(time < 3600)
    {
        let y = time % 60;
        let x = (time - y) / 60;
        if(x < 10)
            x = '0' + x;
        if(y < 10)
            y = '0' + y;
        return x + ':' + y;
    }
    else
    {
        let h = Math.floor(time / 3600);
        time = time - h * 3600;
        let y = time % 60;
        let x = (time - y) / 60;
        if(x < 10)
            x = '0' + x;
        if(y < 10)
            y = '0' + y;
        if(h < 10)
            h = '0' + h;
        return h + ':' + x + ':' + y;
    }
}

export const modifyTo5Percent = decimal => {
    let ans = 0.00;
    while(Math.abs(decimal - ans) > 0.025)
    {
        ans += 0.05
    }

    return ans;
}

let allConfTypes = new Map();

export const setAllConfTypes = list => {
    allConfTypes = list;
}

export const getAllConfTypes = () => {
    return allConfTypes;
}

export const confTypeTranslate = record => {
    let val = !!record.allConferenceType ? record.allConferenceType : record.type;
    return allConfTypes.get(val);
}

const regemail = new RegExp('^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$');
const regmobile = new RegExp('^1[3|4|5|7|8][0-9]{9}$');
export const checkMail = (text) => {
    if(regemail.test(text)){
        return true;
    }else{
        return false;
    }
}

export const checkMobile = (text) => {
    if(regmobile.test(text)){
        return true;
    }else{
        return false;
    }
}


export const getUserNumber = (me) => {
    if(!!me.callState && me.callState.length > 0){
        for(let value of me.callState){
            if(value.type === '30'){
                return value.callnumber;
            }
        }
    }

    return undefined;
}


