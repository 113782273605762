/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Spin} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
const antIcon = <FontAwesomeIcon icon={faSpinner} style={{fontSize : 36}} spin/>


class Loading extends React.Component {
    render () {

        return <div style={{width : '100%', height : '100%', display : 'flex', justifyContent : 'center', alignItems : 'center'}}><div className="dot-pulse"></div></div>
        // return <div style={{width : '100%', height : '100%', display : 'flex', justifyContent : 'center', alignItems : 'center'}}><Spin indicator={antIcon} size="large" /></div>
    }
}

export default Loading;
