/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';


class Framework extends React.Component {
    render () {
        return <div style={{display : 'flex', flexDirection : 'column',  width : '100%', background : 'white' }}>
            {!this.props.hideTitle && <div style={{height : 36, display: 'flex', alignItems : 'center', fontSize : 18, fontWeight : 500, paddingLeft : 12, borderLeft : '4px solid #5143ff'}}>
                <div style={{flex : 1}}><img alt='' src={this.props.icon} style={{height : 20, marginRight : 10}}/>{this.props.title}</div>

                {!!this.props.addition && <div style={{display : 'flex', paddingRight : 30, paddingTop : 30, alignItems : 'center'}}>{this.props.addition}</div>}
            </div>}
            <div style={{flex : 1, overflowY : 'overlay'}}>
                {this.props.content}
            </div>
        </div>
    }
}

export default Framework;